<template>
  <div
    id="state-data-dashboard"
    style="height:100%"
    class="move-left"
  >
    <phi-page
      class="page-data-dashboard"
      :loading="isLoading"
      color="#f3f3f3"
    >
      <div slot="toolbar">
        <mu-icon-button
          @click="$router.go(-1)"
          icon="arrow_back"
        />
        <h1>{{ pageTitle }}</h1>
      </div>

      <div class="phi-card">
        <data-person-dashboard
          class="phi-card-body"
          ref="curdashboard"
          :person-id="personId"
          :dashboard-id="dashboardId"
          @click-view="onClickView"
          @load="onDashboardLoaded"
        ></data-person-dashboard>
      </div>
    </phi-page>
  </div>
</template>

<script>
import DataPersonDashboard from '@/modules/data/components/Person/Dashboard.vue';

export default {
  components: { DataPersonDashboard },

  props: {
    personId: {
      type: String,
      required: false,
      default() {
        return this.$store.state.user.id;
      }
    },

    dashboardId: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      pageTitle: ' ... ',
      isLoading: true,
      currentView: null
    };
  },

  methods: {
    onDashboardLoaded(dash) {
      this.currentView = null;
      this.pageTitle = 'Covid';
      this.isLoading = false;
    },

    onClickView(view) {
      console.log('view clicked', view);
    }
  },

  i18n: {
    en: {
      'StateThreadFeed.Search': 'Search',
      'StateThreadFeed.Results': 'Results',
      'StateThreadFeed.Cancel': '$t(action.cancel)'
    },

    es: {
      'StateThreadFeed.Search': 'Buscar',
      'StateThreadFeed.Results': 'Resultados',
      'StateThreadFeed.Cancel': '$t(action.cancel)'
    }
  }
};
</script>