<template>
  <div class="v3-person-picker">
    <input
      type="text"
      v-model="search"
      class="picker-search ui-native"
      :placeholder="placeholder"
      @input="doInput"
    />

    <div
      class="results ui-card ui-z"
      v-show="search.length > 3"
    >

      <span v-show="isLoading">... buscando ...</span>
      <span v-show="!isLoading && !people.length">No hay resultados</span>
      

      <person-item
        v-for="person in filteredPeople"
        :key="person.id"
        :person="person"
        :secondary="person.typeName"
        class="ui-clickable"
        @click="onClickPerson(person)"
        formal
      ></person-item>
    </div>
  </div>
</template>

<script>
import useApi from '@/modules/api/mixins/useApi.js';
import apiPeople from '@/modules/v3/api/people.js';

import PersonItem from '@/modules/core/components/Person/ListItem.vue';

import { UiItem, UiIcon } from '@/modules/ui/components';

export default {
  name: 'v3-person-picker',
  mixins: [useApi],
  $api: apiPeople,
  components: { PersonItem, UiItem, UiIcon },

  props: {
    year: {
      type: [String, Number],
      required: false,
      default: null
    },

    active: {
      type: Boolean,
      required: false,
      default: null
    },

    type: {
      type: [Number, String, Array],
      required: false,
      default: null
    },

    placeholder: {
      type: String,
      required: false,
      default: 'Buscar'
    }
  },

  data() {
    return {
      search: '',
      people: [],
      timeout: null,
      isLoading: false
    };
  },

  computed: {
    filteredPeople() {
      if (!this.people.length) {
        return [];
      }

      let people = JSON.parse(JSON.stringify(this.people));

      people.forEach(person => {
        person.typeName = person.type == 1 ? 'estudiante' : (person.type == 2 ? 'profesor' : 'familiar');
      });

      people.sort((a,b) => {
        let fullnamea = a.lastname + a.firstname;
        let fullnameb = b.lastname + b.firstname;
        return (fullnamea > fullnameb) ? 1 : (fullnamea < fullnameb ? -1 : 0);
      });

      return people;
    }
  },

  methods: {
    onClickPerson(person) {
      this.$emit('input', person);
      this.people = [];
      this.search = '';
    },

    async fetchPeople() {
      let query = {};
      if (this.year !== null) {
        query.year = this.year;
      }

      if (this.active !== null) {
        query.active = this.active;
      }

      if (this.type !== null) {
        query.type = this.type;
      }

      if (this.search.length) {
        query.search = this.search;
      }

      this.people = await this.$api.getPeople(query);
      this.isLoading = false;
    },

    doInput() {
      if (this.search.trim().length <= 3) {
        this.people = [];
        return;
      }

      this.isLoading = true;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => this.fetchPeople(), 600);
    }
  }
};
</script>

<style lang="scss">
.v3-person-picker {
  position: relative;

  .picker-search {
    width: 100%;
  }

  .results {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    max-height: 300px;
    overflow-y: auto;
  }
}
</style>