<template>
  <div class="data-person-view">
    <template v-if="viewData">
      <cms-page
        :value="viewData.page"
        :data.sync="pageData"
        @update:data="onUpdateData"
      ></cms-page>
    </template>
  </div>
</template>

<script>
import { getProperty, validate } from '@/modules/cms/functions/index.js';

import useIo from '@/app/mixins/useIo.js';
import useApi from '@/modules/api/mixins/useApi.js';
import apiDataViews from '@/modules/data/api/views.js';
import CmsPage from '@/modules/cms/components/Page/Page.vue';

export default {
  name: 'data-person-view',
  mixins: [useIo, useApi],
  $api: apiDataViews,

  components: { CmsPage },

  props: {
    personId: {
      type: String,
      required: true,
    },

    viewId: {
      type: String,
      required: true,
    },

    keys: {
      type: Object,
      required: true,
    },

    autoSave: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      viewData: null,
      pageData: null,
      saveTimeout: null,

      isValid: true,
      validationErrors: [],
    };
  },

  computed: {
    requiredFields() {
      if (
        !this.viewData ||
        !this.viewData.settings ||
        !this.viewData.settings.fields
      ) {
        return [];
      }

      return (
        this.viewData.settings.fields
          .filter((field) => field.options && field.options.required)
          // Ignorar los fields que pertenecen a bloques ocultos
          .filter((field) => {
            let existingBlock =
              this.viewData.page &&
              this.viewData.page.blocks &&
              Array.isArray(this.viewData.page.blocks)
                ? this.viewData.page.blocks.find(
                    (b) =>
                      b['v-model'] == `record.data.${field.name}` && !!b['v-if']
                  )
                : null;

            if (!existingBlock) {
              return true;
            }

            return validate(this.pageData, existingBlock['v-if']);
          })
      );
    },
  },

  mounted() {
    this.fetchView();
  },

  watch: {
    personId: {
      handler() {
        this.fetchView();
      },
    },

    viewId: {
      handler() {
        this.fetchView();
      },
    },

    keys: {
      handler() {
        this.fetchView();
      },
    },
  },

  methods: {
    onUpdateData() {
      this.validateData();
      return this.debounceSave();
    },

    validateData() {
      this.isValid = true;
      this.validationErrors = [];

      for (let i = 0; i < this.requiredFields.length; i++) {
        let field = this.requiredFields[i];

        let value =
          this.pageData && this.pageData.record
            ? getProperty(this.pageData.record.data, field.name)
            : null;

        if (!value || (Array.isArray(value) && !value.length)) {
          this.isValid = false;
          this.validationErrors.push(field);
        }
      }

      this.$emit('update:is-valid', this.isValid);
      return;
    },

    async fetchView() {
      this.viewData = await this.$api.getPersonView(
        this.viewId,
        this.personId,
        this.keys
      );

      this.pageData = {
        record: this.viewData.record,
        person: { id: this.personId },
      };

      this.validateData();
      this.$ioConnect(`data-table-${this.viewData.table}`);
    },

    debounceSave() {
      if (!this.autoSave) {
        return;
      }

      clearTimeout(this.saveTimeout);
      this.saveTimeout = setTimeout(this.save, 400);
    },

    async save() {
      if (!this.isValid) {
        let msg = '';
        this.validationErrors.forEach(
          (field) => (msg += `Debes diligenciar "${field.label}"\n`)
        );
        alert(msg);
        throw 'Validation failed';
      }

      let record = await this.$api.postPersonView(
        this.viewId,
        this.personId,
        this.keys,
        (this.pageData.record ? this.pageData.record.data : null) || null
      );

      this.$io.broadcast('recordChanged', record);

      return record;
    },
  },

  $io: {
    room: null,
  },
};
</script>